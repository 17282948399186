import { getData, httpGet, postData } from './index';
import { URL_GET_OTP_API, URL_VALIDATE_OTP_API, URL_USER_DATA_API, URL_USER_SHOP_LIST_API, URL_GET_ROLES } from '../constants';
import {
    URL_REGISTER_USER,
    URL_SHOP_BY_USER,
    URL_USER_INFO_API,
    URL_USER_STAFF_LIST_API,
    URL_USER_STAFF_LIST_BY_SHOP_API
} from 'constants/index';

export const postGetOTP = async (payload) => {
    try {
        const url = URL_GET_OTP_API;
        const response = await postData(url, payload);

        return response;
    } catch (err) {
        console.log('error when post get otp', err);
    }
};

export const postValidateOTP = async (payload) => {
    try {
        const url = URL_VALIDATE_OTP_API;
        const response = await postData(url, payload);

        return response;
    } catch (err) {
        console.log('error when post validate otp.', err);
    }
};

export const getShopList = async () => {
    try {
        const url = URL_USER_SHOP_LIST_API;
        const response = await getData(url);

        return response.data;
    } catch (err) {
        console.log('error when post validate otp.', err);
    }
};

export const getUserData = async () => {
    try {
        const url = URL_USER_DATA_API;
        const response = await getData(url);

        return response.data;
    } catch (err) {
        console.log('error when post validate otp.', err);
    }
};

export const getUserInfo = async (shopId) => {
    const url = URL_USER_INFO_API.replace('{shopId}', shopId);
    const response = await httpGet({ url });

    return response;
};

export const getStaffList = async () => {
    const url = URL_USER_STAFF_LIST_API;
    const response = await httpGet({ url });

    return response;
};

export const getStaffListByShop = async (shopId) => {
    const url = URL_USER_STAFF_LIST_BY_SHOP_API.replace('{shopId}', shopId);
    const response = await httpGet({ url });

    return response;
};

export const postRegisterUser = async (payload) => {
    try {
        const url = URL_REGISTER_USER;
        const response = await postData(url, payload);

        return response;
    } catch (err) {
        console.log('error when post register user', err);
    }
};

export const getShopByUser = async () => {
    try {
        const url = URL_SHOP_BY_USER;
        const response = await getData(url);

        return response.data;
    } catch (err) {
        console.log('error when get shop by user id.', err);
    }
};

export const getUserRole = async () => {
    try {
        const url = URL_GET_ROLES;
        const response = await getData(url);
        
        return response.data;
    } catch (err) {
        console.log('error when post validate otp.', err);
    }
};