// project import
import getReportMenu from './Report';
import getProductMenu from './Menu';
import getInventoryMenu from './Inventory';
import getBuyerMenu from './Buyer';
// import getMarketplaceIntegrationMenu from './MarketplaceIntegration';
import getAttendanceMenu from './Attendance';
import getTableManagementMenu from './TableManagement';

// ==============================|| MENU ITEMS ||============================== //

// const menuItems = {
//     items: [Report, Menu, Inventory, Buyer /*, MarketplaceIntegration */]
// };

const getMenuItems = ({ isVerified, $t = () => '', permission }) => ({
    items: [
        getReportMenu({ isVerified, $t, permission }),
        getAttendanceMenu({ isVerified, $t, permission }),
        getProductMenu({ isVerified, $t, permission }),
        getInventoryMenu({ isVerified, $t, permission }),
        getBuyerMenu({ isVerified, $t, permission }),
        getTableManagementMenu({ isVerified, $t, permission })
        // getMarketplaceIntegrationMenu({ isVerified })
    ]
});

export default getMenuItems;
