// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import getMenuItems from 'menu-items';

import { useShopIdHook } from 'hooks/common/shopId';
import { useLang } from 'hooks/common/lang';
import { useSelector } from 'react-redux';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const { getShop } = useShopIdHook();
    const { $t } = useLang();

    const shop = getShop();
    const shopCompleteState = useSelector((state) => state.shop);
    const permission = shopCompleteState.permission;
    let isVerified = shop?.isVerified ?? false;

    const navGroups = getMenuItems({ isVerified, $t, permission }).items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
