// assets
import CommonMenuIcon from 'components/commons/MenuIcon';

import TableLayoutIcon from 'assets/images/icons/menuSVG/table-layout.svg';
import TableAreaIcon from 'assets/images/icons/menuSVG/table-area.svg';
import TableManagementIcon from 'assets/images/icons/menuSVG/table-management.svg';
import { ROLE_LIST } from 'constants/index';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const getTableManagementMenu = ({ $t, permission }) => {
    if(permission && permission.indexOf(ROLE_LIST.TABLE_VIEW) !== -1) {
        return {
            id: 'table-management-menu',
            title: $t('sidebar.OpMenuGroupTableManagement'),
            type: 'group',
            children: [
                {
                    id: 'table-layout',
                    title: $t('table.OpTableLayoutTitle'),
                    type: 'item',
                    url: '/table-management/table-layout',
                    icon: CommonMenuIcon(TableLayoutIcon),
                    breadcrumbs: false
                },
                {
                    id: 'table-area',
                    title: $t('area.OpTitle'),
                    type: 'item',
                    url: '/table-management/area',
                    icon: CommonMenuIcon(TableAreaIcon),
                    breadcrumbs: false
                },
                {
                    id: 'table',
                    title: $t('table.OpTitle'),
                    type: 'item',
                    url: '/table-management/table',
                    icon: CommonMenuIcon(TableManagementIcon),
                    breadcrumbs: false
                }
            ]
        };
    } else {
        return {};
    }
    
};

export default getTableManagementMenu;
