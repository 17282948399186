import { LS_AUTH, LS_USER } from 'constants/index';

export const httpGet = async ({ url }) => {
    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Op-Au': localStorage.getItem(LS_AUTH),
            'Op-User-Id': localStorage.getItem(LS_USER)
        }
    });
    const jsonResponse = await response.json();
    return jsonResponse;
};

export const httpPost = async ({ url, payload, isFile = false }) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Op-Au': localStorage.getItem(LS_AUTH),
            'Op-User-Id': localStorage.getItem(LS_USER),
            ...(isFile ? {} : { 'Content-Type': 'application/json' })
        },
        body: isFile ? payload : JSON.stringify(payload)
    });
    const jsonResponse = await response.json();
    return jsonResponse;
};

export const httpPut = async ({ url, payload }) => {
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Op-Au': localStorage.getItem(LS_AUTH),
            'Op-User-Id': localStorage.getItem(LS_USER)
        },
        body: JSON.stringify(payload)
    });
    const jsonResponse = await response.json();
    return jsonResponse;
};

export const httpDelete = async ({ url, payload }) => {
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Op-Au': localStorage.getItem(LS_AUTH),
            'Op-User-Id': localStorage.getItem(LS_USER)
        },
        body: JSON.stringify(payload)
    });
    const jsonResponse = await response.json();
    return jsonResponse;
};

export const httpGetRaw = async ({ url }) => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Op-Au': localStorage.getItem(LS_AUTH),
            'Op-User-Id': localStorage.getItem(LS_USER),
            'Content-Type': 'application/json'
        }
    });

    return response;
};

export const httpPostRaw = async ({ url, payload, isFile = false }) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Op-Au': localStorage.getItem(LS_AUTH),
            'Op-User-Id': localStorage.getItem(LS_USER),
            ...(isFile ? {} : { 'Content-Type': 'application/json' })
        },
        body: isFile ? payload : JSON.stringify(payload)
    });

    return response;
};

export const getData = async (url, requestPayload) => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Op-Au': localStorage.getItem(LS_AUTH),
                'Op-User-Id': localStorage.getItem(LS_USER)
            },
            body: JSON.stringify(requestPayload)
        });
        return response.json();
    } catch (err) {
        // TODO: handle error
    }
};

export const getDataXls = async (url) => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Op-Au': localStorage.getItem(LS_AUTH),
                'Op-User-Id': localStorage.getItem(LS_USER)
            }
        });
        return response.text();
    } catch (err) {
        console.log(err);
        // TODO: handle error
    }
};

export const postDataXls = async (url, payload) => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Op-Au': localStorage.getItem(LS_AUTH),
                'Op-User-Id': localStorage.getItem(LS_USER)
            },
            body: JSON.stringify(payload)
        });
        return response.text();
    } catch (err) {
        console.log(err);
        // TODO: handle error
    }
};

export const postData = async (url, requestPayload, token) => {
    try {
        if (token) {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'firebase-token': token
                },
                body: JSON.stringify(requestPayload)
            });
            return response.json();
        } else {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Op-Au': localStorage.getItem(LS_AUTH),
                    'Op-User-Id': localStorage.getItem(LS_USER)
                },
                body: JSON.stringify(requestPayload)
            });
            return response.json();
        }
    } catch (err) {
        return [];
        // TODO: handle error
    }
};
export const putData = async (url, requestPayload, token) => {
    try {
        if (token) {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'firebase-token': token
                },
                body: JSON.stringify(requestPayload)
            });
            return response.json();
        } else {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Op-Au': localStorage.getItem(LS_AUTH),
                    'Op-User-Id': localStorage.getItem(LS_USER)
                },
                body: JSON.stringify(requestPayload)
            });
            return response.json();
        }
    } catch (err) {
        // TODO: handle error
    }
};

export const postImage = async (url, requestPayload) => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Op-Au': localStorage.getItem(LS_AUTH),
                'Op-User-Id': localStorage.getItem(LS_USER)
            },
            body: requestPayload
        });
        return response.json();
    } catch (err) {
        return [];
        // TODO: handle error
    }
};
