// assets
import { UsergroupAddOutlined } from '@ant-design/icons';
import CustomerIcon from 'assets/images/icons/menuSVG/customer.svg';
import BulkMessageIcon from 'assets/images/icons/menuSVG/bulk-message.svg';
import VoucherIcon from 'assets/images/icons/menuSVG/voucher.svg';
import CommonMenuIcon from 'components/commons/MenuIcon';
import { ROLE_LIST } from 'constants/index';

// icons
const icons = {
    UsergroupAddOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const getBuyerMenu = ({ $t, isVerified, permission }) => {
    if(permission && permission.indexOf(ROLE_LIST.CRM_VIEW) !== -1) {
        return {
            id: 'group-buyer',
            title: $t('sidebar.OpMenuGroupBuyer'),
            type: 'group',
            children: [
                {
                    id: 'buyer',
                    title: $t('buyers.OpTitle'),
                    type: 'item',
                    url: '/buyers',
                    icon: CommonMenuIcon(CustomerIcon),
                    breadcrumbs: false
                },
                isVerified && {
                    id: 'bulk-message',
                    title: $t('bulk_message.OpTitle'),
                    type: 'item',
                    url: '/bulk-messages',
                    icon: CommonMenuIcon(BulkMessageIcon),
                    breadcrumbs: false
                },
                {
                    id: 'voucher',
                    title: $t('vouchers.OpTitle'),
                    type: 'item',
                    url: '/vouchers',
                    icon: CommonMenuIcon(VoucherIcon),
                    breadcrumbs: false
                }
            ].filter(Boolean)
        };
    } else {
        return {};
    }
    
};

export default getBuyerMenu;
