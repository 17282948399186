// assets
import CommonMenuIcon from 'components/commons/MenuIcon';

import AttendanceHistoryIcon from 'assets/images/icons/menuSVG/attendance-history.svg';
import AttendanceApprovalIcon from 'assets/images/icons/menuSVG/attendance-approval.svg';
import AttendanceScheduleIcon from 'assets/images/icons/menuSVG/attendance-schedule.svg';
import { ROLE_LIST } from 'constants/index';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const getAttendanceMenu = ({ $t, permission }) => {
    if (permission && permission.indexOf(ROLE_LIST.ATTENDANCE_VIEW) !== -1) {
        return {
            id: 'group-attendance',
            title: $t('sidebar.OpMenuGroupAttendance'),
            type: 'group',
            children: [
                {
                    id: 'attendance-history',
                    title: $t('attendance_history.OpTitle'),
                    type: 'item',
                    url: '/attendance/history',
                    icon: CommonMenuIcon(AttendanceHistoryIcon),
                    breadcrumbs: false
                },
                {
                    id: 'attendance-schedule',
                    title: $t('attendance_schedule.OpTitle'),
                    type: 'item',
                    url: '/attendance/schedule',
                    icon: CommonMenuIcon(AttendanceScheduleIcon),
                    breadcrumbs: false
                },
                {
                    id: 'attendance-approval',
                    title: $t('attendance_approval.OpTitle'),
                    type: 'item',
                    url: '/attendance/approval',
                    icon: CommonMenuIcon(AttendanceApprovalIcon),
                    breadcrumbs: false
                }
            ]
        };
    } else {
        return {};
    }
};

export default getAttendanceMenu;
