// assets
import { ReadOutlined, ShoppingCartOutlined } from '@ant-design/icons';

import CommonMenuIcon from 'components/commons/MenuIcon';
import SupplierIcon from 'assets/images/icons/menuSVG/supplier.svg';
import InventoryCategoryIcon from 'assets/images/icons/menuSVG/inventoryCategory.svg';
import RecipeIcon from 'assets/images/icons/menuSVG/recipe.svg';
import IngredientIcon from 'assets/images/icons/menuSVG/ingredient.svg';
import InventoryFlowIcon from 'assets/images/icons/menuSVG/inventory-flow.svg';
import ConversionUnitIcon from 'assets/images/icons/menuSVG/conversion-unit.svg';
import { ROLE_LIST } from 'constants/index';

// icons
const icons = {
    ReadOutlined,
    ShoppingCartOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const getInventoryMenu = ({ $t, permission }) => {
    if (permission && (permission.indexOf(ROLE_LIST.INVENTORY_VIEW) !== -1 || permission.indexOf(ROLE_LIST.INVENTORY_RECIPE_VIEW) !== -1)) {
        return {
            id: 'group-inventory',
            title: $t('sidebar.OpMenuGroupInventory'),
            type: 'group',
            children: [
                {
                    id: 'ingredients',
                    title: $t('ingredients.OpTitle'),
                    type: 'item',
                    url: '/ingredients',
                    icon: CommonMenuIcon(IngredientIcon),
                    breadcrumbs: false,
                    show: permission && permission.indexOf(ROLE_LIST.INVENTORY_VIEW) !== -1
                },
                {
                    id: 'inventory-flow',
                    title: $t('inventory_flow.OpTitle'),
                    type: 'item',
                    url: '/inventory-flow',
                    icon: CommonMenuIcon(InventoryFlowIcon),
                    breadcrumbs: false,
                    show: permission && permission.indexOf(ROLE_LIST.INVENTORY_VIEW) !== -1
                },
                {
                    id: 'supplier',
                    title: $t('suppliers.OpTitle'),
                    type: 'item',
                    url: '/suppliers',
                    icon: CommonMenuIcon(SupplierIcon),
                    breadcrumbs: false,
                    show: permission && permission.indexOf(ROLE_LIST.INVENTORY_VIEW) !== -1
                },
                {
                    id: 'categories',
                    title: $t('inventory_category.OpTitle'),
                    type: 'item',
                    url: '/categories-inventory',
                    icon: CommonMenuIcon(InventoryCategoryIcon),
                    breadcrumbs: false,
                    show: permission && permission.indexOf(ROLE_LIST.INVENTORY_VIEW) !== -1
                },
                {
                    id: 'recipes',
                    title: $t('recipes.OpTitle'),
                    type: 'item',
                    url: '/recipes',
                    icon: CommonMenuIcon(RecipeIcon),
                    breadcrumbs: false,
                    show: permission && permission.indexOf(ROLE_LIST.INVENTORY_RECIPE_VIEW) !== -1
                },
                {
                    id: 'conversion-units',
                    title: $t('conversion_unit.OpTitle'),
                    type: 'item',
                    url: '/conversion-units',
                    icon: CommonMenuIcon(ConversionUnitIcon),
                    breadcrumbs: false,
                    show: permission && permission.indexOf(ROLE_LIST.INVENTORY_VIEW) !== -1
                }
            ]
        };
    } else {
        return {};
    }
};

export default getInventoryMenu;
