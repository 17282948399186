// assets
import CommonMenuIcon from 'components/commons/MenuIcon';
import ProductIcon from 'assets/images/icons/menuSVG/product.svg';
import VariantIcon from 'assets/images/icons/menuSVG/variant.svg';
import ProductCategoryIcon from 'assets/images/icons/menuSVG/category-product.svg';
import MasterVariantIcon from 'assets/images/icons/menuSVG/master-variant.svg';
import MasterProductCategoryIcon from 'assets/images/icons/menuSVG/master-product-category.svg';
import MasterProductIcon from 'assets/images/icons/menuSVG/master-products.svg';
import { ROLE_LIST } from 'constants/index';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const getProductMenu = ({ $t, permission }) => {
    if (permission && (permission.indexOf(ROLE_LIST.MENU_VIEW) !== -1 || permission.indexOf(ROLE_LIST.MENU_EDIT) !== -1)) {
        return {
            id: 'group-menu',
            title: $t('sidebar.OpMenuGroupProduct'),
            type: 'group',
            children: [
                {
                    id: 'product',
                    title: $t('products.OpTitle'),
                    type: 'item',
                    url: '/products',
                    icon: CommonMenuIcon(ProductIcon),
                    breadcrumbs: false,
                    show: permission && permission.indexOf(ROLE_LIST.MENU_VIEW) !== -1
                },
                {
                    id: 'variants',
                    title: $t('variants.OpTitle'),
                    type: 'item',
                    url: '/variants',
                    icon: CommonMenuIcon(VariantIcon),
                    breadcrumbs: false,
                    show: permission && permission.indexOf(ROLE_LIST.MENU_VIEW) !== -1
                },
                {
                    id: 'product-category',
                    title: $t('product_categories.OpTitle'),
                    type: 'item',
                    url: '/product-category',
                    icon: CommonMenuIcon(ProductCategoryIcon),
                    breadcrumbs: false,
                    show: permission && permission.indexOf(ROLE_LIST.MENU_VIEW) !== -1
                },
                {
                    id: 'master-products',
                    title: $t('master_products.OpMasterProduct'),
                    type: 'item',
                    url: '/master-products',
                    icon: CommonMenuIcon(MasterProductIcon),
                    breadcrumbs: false,
                    show: permission && permission.indexOf(ROLE_LIST.MENU_EDIT) !== -1
                },
                {
                    id: 'master-variants',
                    title: $t('master_variants.OpTitle'),
                    type: 'item',
                    url: '/master-variants',
                    icon: CommonMenuIcon(MasterVariantIcon),
                    breadcrumbs: false,
                    show: permission && permission.indexOf(ROLE_LIST.MENU_EDIT) !== -1
                },
                {
                    id: 'master-product-category',
                    title: $t('master_product_categories.OpTitle'),
                    type: 'item',
                    url: '/master-product-category',
                    icon: CommonMenuIcon(MasterProductCategoryIcon),
                    breadcrumbs: false,
                    show: permission && permission.indexOf(ROLE_LIST.MENU_EDIT) !== -1
                }
            ]
        };
    } else {
        return {};
    }
};

export default getProductMenu;
